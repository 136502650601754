import { Link } from "react-router-dom";
import { useGetProjects } from "../hooks/useGetPosts";
import styled from "styled-components";
import { ImageWithDropShadow } from "../components/ImageWithDropShadow";

const imageHeight = "400px";

const PageWrapper = styled.div`
  padding 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${imageHeight} ${imageHeight};
  grid-gap: 40px;
  
  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-gap: 60px;
    padding 20px;
  };
`;

const StyledGridItem = styled.div`
  height: ${imageHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Home = () => {
  const { data: projects } = useGetProjects();

  return (
    <PageWrapper>
      {!!projects &&
        projects?.map((project) => (
          <Link
            key={project?.slug?.current}
            to={`/projects/${project?.slug?.current}`}
          >
            <StyledGridItem>
              <ImageWithDropShadow
                projectTitle={project.title}
                src={project.preview}
              />
            </StyledGridItem>
          </Link>
        ))}
    </PageWrapper>
  );
};

export default Home;
