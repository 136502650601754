import { Routes, Route, Link, NavLink } from "react-router-dom";
import styled from "styled-components";

// pages
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Project } from "./pages/Project";

// components
import { ReturnToTop } from "./components/ReturnToTop";

// hooks
import { useGetAuthor } from "./hooks/useGetPosts";

import "./App.css";

const StyledHeader = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(61, 61, 61);
  padding: 0 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
`;

const StyledHeaderLinkContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledHeaderLinks = styled(StyledHeaderLinkContainer)`
  @media screen and (min-width: 501px) {
    justify-content: flex-end;
  }

  & > a {
    display: inline-block;
    padding: 20px 15px;
    font-weight: bold;
    color: #e5e5e5;
    transition: none;

    @media screen and (max-width: 500px) {
      width: 100%;
      text-align: center;
    }

    &.active {
      color: rgb(66, 66, 66);
      background: rgb(255, 200, 0);
    }
  }
`;

const StyledHeaderLogo = styled(StyledHeaderLinkContainer)`
  @media screen and (min-width: 501px) {
    flex-direction: row;
  }
`;

const StyledH1 = styled.h2`
  color: #e5e5e5;

  &:hover {
    color: rgb(255, 200, 0);
  }
`;

const PageWrapper = styled.div`
  margin-top: 20px;
`;

function App() {
  const { data: authorData } = useGetAuthor();
  const author = authorData?.[0];

  return (
    <div className="App">
      <StyledHeader>
        <StyledHeaderLogo>
          <Link to="/">
            <StyledH1>{author?.name}</StyledH1>
          </Link>
        </StyledHeaderLogo>
        <StyledHeaderLinks>
          <NavLink to="/">Work</NavLink>
          <NavLink to="/about">About</NavLink>
        </StyledHeaderLinks>
      </StyledHeader>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects/:projectSlug" element={<Project />} />
        </Routes>
      </PageWrapper>
      <ReturnToTop showAtScrollPosition={-1000} />
    </div>
  );
}

export default App;
