import { useParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Image } from "antd";
import { useGetProject } from "../hooks/useGetPosts";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { PortableText } from "@portabletext/react";

const GlobalStyledModalBackground = createGlobalStyle`
  .ant-image-preview-root .ant-image-preview-mask {
    background-color: #242424f2;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PageTextWrapper = styled.article`
  max-width: 1444px;
  padding: 25px;
`;

const GridLayoutImageWrapper = styled.div`
  padding: 25px;
  max-width: 1444px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 15px;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 2fr);
    grid-gap: 15px;
  }
`;

const ImagesWrapper = styled.div`
  padding: 25px;
  max-width: 800px;
`;

const StyledImage = styled(Image)``;

const StyledGridImage = styled(StyledImage)`
width: 100%;
height: 100%;
object-fit: cover;
${({currentPageWidth}) => currentPageWidth > 500 ? 'aspect-ratio: 1;' : 'aspect-ratio: initial;'}


  & .ant-image-preview-mask {
    background-color: rgb(92 92 92 / 94%);
  }
`;

const SingleImageWrapper = styled.div`
  margin-bottom: 50px;

  @media screen and (max-width: 500px) {
    margin-bottom: 25px;
  }
`;

const ImageCaption = styled.p`
  text-align: right;
`;

export const GridLayout = ({ width, projectData }) => {
  return (
    <GridLayoutImageWrapper>
      <Image.PreviewGroup preview={{toolbarRender: () => undefined}}>
        {projectData?.imagesGallery?.map((image) => {
          return (
            <StyledGridImage
              preview={width > 500}
              key={image._key}
              alt={image.alt || null}
              src={image.asset.url}
              currentPageWidth={width}
            />
          );
        })}
      </Image.PreviewGroup>
    </GridLayoutImageWrapper>
  );
};

export const ScrollLayout = ({ width, projectData }) => {
  return (
    <ImagesWrapper>
      {projectData?.imagesGallery?.map((image) => {
        return (
          <SingleImageWrapper key={image._key}>
            <StyledImage
              src={image.asset.url}
              alt={image?.alt}
              preview={false}
            />
            {!!image.title && <ImageCaption>{image.title}</ImageCaption>}
          </SingleImageWrapper>
        );
      })}
    </ImagesWrapper>
  );
};

export const Project = () => {
  const { projectSlug } = useParams();
  const { data: project } = useGetProject(projectSlug);
  const { width } = useWindowDimensions();

  const projectData = project?.[0];

  return (
    <>
      <ContentWrapper>
        <GlobalStyledModalBackground />
        {!!projectData && (
          <PageTextWrapper>
            <h2>{projectData?.title}</h2>
            {!!projectData.body && <PortableText value={projectData.body} />}
          </PageTextWrapper>
        )}
      </ContentWrapper>
      <ContentWrapper>
        {projectData?.useGridLayout ? (
          <GridLayout width={width} projectData={projectData} />
        ) : (
          <ScrollLayout width={width} projectData={projectData} />
        )}
      </ContentWrapper>
    </>
  );
};

export default Project;
