import styled from "styled-components";

const StyledImageWithDotDropSHadow = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.imageUrl}) no-repeat center center;
  background-size: cover;
  z-index: 2;
  // border: 2px solid rgb(255, 200, 0);

  & > .overlay {
    font-family: "Chakra Petch", sans-serif;
    font-weight: bold;
    font-size: 48px;
    color: white;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background: #00000073;
    display: none;

    @media screen and (pointer: coarse) {
      display: flex;
      font-size: 30px;
      height: 100%;
      width: 100%;
      background: #00000060;
    }
  }

  &:hover {
    & > .overlay {
      display: flex;
    }
  }
`;

const DropShadow = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128.07 126.55'%3E%3Ccircle cx='64.04' cy='63.28' r='31.79'/%3E%3C/svg%3E");
  background-size: 8px 8px;
  background-attachment: scroll;
  background-position: bottom right;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: 18px;
  top: 17px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const ImageWithDropShadow = ({
  src,
  projectTitle,
  height,
  width,
  alt,
}) => {
  return (
    <>
      <StyledImageWithDotDropSHadow imageUrl={src}>
        <div className="overlay">{projectTitle}</div>
      </StyledImageWithDotDropSHadow>
      <DropShadow />
    </>
  );
};
