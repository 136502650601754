import { Image } from "antd";
import { useGetAuthor } from "../hooks/useGetPosts";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

const StyledImage = styled(Image)`
  border-radius: 10px;
`;

export const About = () => {
  const { data: authorData } = useGetAuthor();
  const author = authorData?.[0];

  if (!author) {
    return <div>it does a loading</div>;
  }
  return (
    <>
      <main>
        <h2>About</h2>
        <StyledImage width={500} src={author.authorImage} preview={false} />
        <PortableText value={author?.bio} />
      </main>
    </>
  );
};

export default About;
