import { useQuery } from "react-query";
import sanityClient from "../client";

export const getAuthor = () => {
  return sanityClient
    .fetch(
      `
    *[_type == "author"]{
        name,
        bio,
        "authorImage": image.asset->url
    }
  `
    )
    .then((data) => data);
};
export const getProjects = () => {
  return sanityClient
    .fetch(
      `
    *[_type == "project"] | order(publishedAt desc) {
      title,
      slug,
      publishedAt,
      "preview": mainImage.asset->url
    }
    `
    )
    .then((data) => data);
};

export const getProject = (projectSlug) => () => {
  const query = `
  *[_type == "project" && slug.current == "${projectSlug}"]{
    title,
    slug,
    body,
    useGridLayout,
    "preview": mainImage.asset->url,
    imagesGallery[]{
      _key,
      title,
      alt,
      asset->{
        url,
      }
    }
  }
  `;

  return sanityClient.fetch(query).then((data) => data);
};

export const useGetAuthor = () => {
  return useQuery(["author"], getAuthor);
};

export const useGetProjects = () => {
  return useQuery("projects", getProjects);
};

export const useGetProject = (projectSlug) => {
  return useQuery(["project", projectSlug], getProject(projectSlug));
};
