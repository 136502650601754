import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import styled from "styled-components";

const ReturnToTopCircle = styled.div`
  width: 50px;
  height: 50px;
  background: rgb(61, 61, 61);
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  z-index: 102;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const onClickScrollTop = () => {
  window.scrollTo(0, 0);
};

export const ReturnToTop = ({ showAtScrollPosition }) => {
  const [showReturnToTop, setShowReturnToTop] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < showAtScrollPosition;
      if (isShow !== showReturnToTop) setShowReturnToTop(isShow);
    },
    [showReturnToTop]
  );

  if (showReturnToTop) {
    return (
      <ReturnToTopCircle onClick={onClickScrollTop}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="18 15 12 9 6 15" />
        </svg>
      </ReturnToTopCircle>
    );
  }
  return null;
};
